<template>
  <div>
    <!-- This is the main landing page. The text here could be collapsable, but chose not to. -->
    <h3>LOL HER VAR DEN XD</h3>
    <p>Edit Cands</p>
    <router-link to="/">Home</router-link>
    <p></p>
    </div>

</template>

<script>
export default {
  name: "NewCand"
}
</script>

<style scoped>

</style>